/*
 * Copyright 2024 LLC Campus.
 */

/**
 * @returns {Promise<{
 * id: number,
 * fio: boolean,
 * phone: boolean,
 * email: boolean,
 * cv: boolean,
 * university: boolean,
 * course: boolean,
 * cover_letter: boolean
 * }>}
 */
export async function fetchJobResponseForm (baseUrl, formId) {
  const response = await fetch(baseUrl + 'items/response_forms/' + formId)
  const responseJson = await response.json()
  return responseJson.data
}

/**
 * @returns {Promise<{
 *   id: number,
 *   response_form: number
 * }>}
 */
export async function fetchJob (baseUrl, jobId) {
  const response = await fetch(baseUrl + 'items/jobs/' + jobId)
  const responseJson = await response.json()
  return responseJson.data
}

export async function postNewJobResponse (baseUrl, jobId, data) {
  const response = await fetch(baseUrl + 'items/applications/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      job_id: jobId,
      ...data
    })
  })
  if (!response.ok) {
    const responseJson = await response.json()
    throw new Error(responseJson.message)
  }
}

/**
 * Загружает файл в директус и возвращает id файла.
 * @param baseUrl{String}
 * @param file
 * @param folderId{String} folderId, узнавать девтулами при загрузке файла в директусе
 * @returns {Promise<any>}
 */
export async function uploadFile (baseUrl, file, folderId) {
  if (!file) {
    throw new Error("No file passed to uploadFile")
  }

  const formData = new FormData()
  formData.append('title', file.name)
  formData.append('folder', folderId)
  formData.append('file', file) // file должен быть последним в formData!

  try {
    const response = await fetch(baseUrl + 'files', {
      method: 'POST',
      body: formData,
    })

    if (!response.ok) {
      const responseJson = await response.json()
      console.error(responseJson)
      throw new Error(responseJson.message)
    }

    const result = await response.json()

    return result.data.id
  } catch (error) {
    console.error('Error uploadFile:', error)
    throw error
  }
}