/*
 * Copyright 2024 LLC Campus.
 */
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { checkStatus } from '../services/api'
import '../styles/SuccessPage.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MoonLoader } from 'react-spinners'
import analytics, { AnalyticsEvents } from '../analytics'
import CopyCode from '../components/CopyCode'
import CourseActivated from '../components/CourseActivated'
import Wrapper from '../components/Wrapper'

const SuccessPage = () => {
  const location = useLocation()
  const transactionId = new URLSearchParams(location.search).get('transactionId')
  const { activationLink, returnPath } = JSON.parse(localStorage.getItem('purchaseData'))
  const [code, setCode] = useState(null)
  const [isActivated, setActivated] = useState(false)
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (transactionId) {
      const checkResult = async () => {
        try {
          const response = await checkStatus(transactionId)
          const code = response.code
          const isActivated = response.isActivated
          if (code !== undefined) {
            setCode(code)
            if (isActivated === true) {
              setActivated(isActivated)
              setDescription('Курс доступен в приложении')
            } else {
              setDescription('Код будет выслан на твою электронную почту')
            }
            setLoading(false)
          } else {
            setTimeout(checkResult, 5000)
          }
        } catch (error) {
          toast.error(error.message)
          setError(error)
          setLoading(false)
          analytics.sendEvent(AnalyticsEvents.PURCHASE_ERROR, analytics.getLocalStorageMeta())
        }
      }

      checkResult()
    }
  }, [transactionId])

  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      toast.dismiss()
      navigator.clipboard.writeText(code).then(() => {
        toast.info('Код успешно скопирован')
        analytics.sendEventOnce(AnalyticsEvents.CODE_COPIED, analytics.getLocalStorageMeta())
      })
    } else {
      toast.error('Копирование в буфер обмена не поддерживается в вашем браузере.')
    }
  }

  const handleClickEvent = (event) => {
    analytics.sendEventOnce(event, analytics.getLocalStorageMeta())
  }

  return (
    <div className="overlay">
      <ToastContainer/>
      {error && (
        <div className="error-container">
          <p>Произошла ошибка при обработке платежа.</p>
          <p>Причина: {error.message}</p>
        </div>
      )}

      {!loading && !error && (
        <div className="success-container">
          <h1>Поздравляем с покупкой!</h1>
          {code ? isActivated ? (
            <CourseActivated
              description={description}
            />
          ) : (
            <CopyCode
              code={code}
              description={description}
              handleCopy={handleCopy}
            />
          ) : (
            <p>Код не получен, возможно, платеж еще обрабатывается. Попробуйте обновить страницу позже.</p>
          )}
          {code && !isActivated && (
            <Wrapper>
              <Link to={activationLink + code} onClick={() => { handleClickEvent(AnalyticsEvents.ACTIVATION_CLICK) }}
                    className="activation-button">Активировать сейчас</Link>
              <div></div>
              <Link to={returnPath} onClick={() => { handleClickEvent(AnalyticsEvents.RETURN_TO_STORE) }}
                    className="return-button">Вернуться в магазин</Link>
            </Wrapper>
          )}
          {code && isActivated && (
            <Link to='campus://mycourses' onClick={() => { handleClickEvent(AnalyticsEvents.RETURN_TO_APP) }}
                  className="return-button">Назад в приложение</Link>
          )}
        </div>
      )}

      {loading && !error && (
        <div className="loader-container">
          <MoonLoader
            size={50}
            color="#36D7B7"
            loading={loading}
          />
          <p className="loader-text">Платеж в обработке, пожалуйста, подождите...</p>
        </div>
      )}
    </div>
  )
}

export default SuccessPage