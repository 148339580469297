/*
 * Copyright 2024 LLC Campus.
 */

/**
 * Компонент с промокодом и его копированием
 * @param code {String} промокод
 * */
const CourseActivated = ({ description }) => {
  return (<div>
    <p>Курс активирован!</p>
    <p>{description}</p>
  </div>)
}

export default CourseActivated