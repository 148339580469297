/*
 * Copyright 2024 LLC Campus.
 */

import React, { useEffect, useRef, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { fetchJob, fetchJobResponseForm, postNewJobResponse, uploadFile } from '../services/directusApi'
import Wrapper from '../components/Wrapper'
import { getWebsiteConfig } from '../services/api'
import '../styles/JobReplyPage.css'

function JobReplyPage () {
  const location = useLocation()
  const jobId = new URLSearchParams(location.search).get('jobId')
  const directusUrl = useRef(null)

  const [jobResponseForm, setJobResponseForm] = useState({})
  const [hasErrorToast, setHasErrorToast] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [patronymic, setPatronymic] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [cv, setCv] = useState(null)
  const [university, setUniversity] = useState('')
  const [course, setCourse] = useState(0)
  const [coverLetter, setCoverLetter] = useState('')

  const submitButtonRef = useRef() // ref на кнопку отправки формы

  useEffect(() => {
    let isMounted = true
    if (!jobId) {
      if (isMounted) {
        toast.error('Ошибка: необходима передача jobId. Страница не может быть загружена.')
        setHasErrorToast(true)
      }
      return
    }

    const fetchData = async () => {
      try {
        const websiteConfig = await getWebsiteConfig()
        directusUrl.current = websiteConfig.directusApiUrl

        const jobItem = await fetchJob(directusUrl.current, jobId)
        const jobResponseFormItem = await fetchJobResponseForm(directusUrl.current, jobItem.response_form)
        setJobResponseForm(jobResponseFormItem)
      } catch (error) {
        if (!hasErrorToast && isMounted) {
          toast.error(error.message)
          setHasErrorToast(true)
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [jobId, hasErrorToast, directusUrl])

  const handleSubmitClick = async () => {
    submitButtonRef.current.disabled = true

    // Заметка: здесь недопустимо пропускать значение null, когда в форме поле есть
    const jobResponseData = {
      first_name: jobResponseForm.fio ? firstName : null,
      last_name: jobResponseForm.fio ? lastName : null,
      patronymic: jobResponseForm.fio ? patronymic : null,
      email: jobResponseForm.email ? email : null,
      phone: jobResponseForm.phone ? phone : null,
      university: jobResponseForm.university ? university : null,
      course: jobResponseForm.course ? course : null,
      // cv добавляется в тело запроса отдельно, тут нужно его добавить только для проверки
      cv: jobResponseForm.cv ? true : null,
      cover_letter: jobResponseForm.cover_letter ? coverLetter : null
    }

    // Ищем пустое поле (все поля формы должны быть заполнены)
    const hasEmptyField = Object.values(jobResponseData).some((value) => {
      if (value === null) return false // Пропускаем отсутствующие поля
      if (typeof value === 'string') {
        return !value?.trim() // пустая строка = false, trim убирает все whitespace.
      }
      return !value // 0, NaN, undefined = false, переворачиваем - вернёт true ток если был 0 и прочие невалиды.
    })

    if (hasEmptyField) {
      toast.error('Все поля должны быть заполнены')
      submitButtonRef.current.disabled = false
      return
    }

    if (jobResponseForm.email) {
      const isEmailValid = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
      if (!isEmailValid) {
        toast.error('Email должен быть корректным')
        submitButtonRef.current.disabled = false
        return
      }
    }
    if (jobResponseForm.phone) {
      const isPhoneValid = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/.test(phone)
      if (!isPhoneValid) {
        toast.error('Номер телефона должен быть корректным')
        submitButtonRef.current.disabled = false
        return
      }
    }

    if (jobResponseForm.cv) {
      if (!cv) {
        toast.error('Необходим файл резюме')
        submitButtonRef.current.disabled = false
        return
      }
      // 3-й аргумент - folderId - по идее одинаковый везде быть должен.
      const fileId = await uploadFile(directusUrl.current, cv, 'c9ed1cfb-a96e-4b7b-81cb-82e59c484992')
      jobResponseData.cv = fileId
    }

    // Создаём отклик в директусе
    await postNewJobResponse(directusUrl.current, jobId, jobResponseData)
    toast.success('Отклик отправлен!')
  }

  return (
    <div>
      <ToastContainer/>
      <div className={`promocode-catalog`}>
        <div className="promocode-catalog-header">
          <div className="promocode-catalog-header-title">Отклик на вакансию</div>
        </div>

        <div className="email-input-container">
          {jobResponseForm.fio && <Wrapper>
            <label>Имя</label>
            <input
              type="text"
              placeholder="Иван"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              className={`email-input`}
            />
            <label>Фамилия</label>
            <input
              type="text"
              placeholder="Иванов"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              className={`email-input`}
            />
            <label>Отчество</label>
            <input
              type="text"
              placeholder="Иванович"
              value={patronymic}
              onChange={(event) => setPatronymic(event.target.value)}
              className={`email-input`}
            />
          </Wrapper>}
          {jobResponseForm.email && <Wrapper>
            <label>Email</label>
            <input
              type="email"
              placeholder="your@email.com"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className={`email-input`}
            />
          </Wrapper>}
          {jobResponseForm.phone && <Wrapper>
            <label>Номер телефона</label>
            <input
              type="text"
              placeholder="+71234567890"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              className={`email-input`}
            />
          </Wrapper>}
          {jobResponseForm.cv && <Wrapper>
            <label>Резюме</label>
            <label className={`input-file`}>
              <input
                type="file"
                onChange={(event) => setCv(event.target.files[0])}
                className={`email-input`}
                accept=".pdf,.doc,.docx"
              />
              <span>{cv ? cv.name : `Выберите файл`}</span>
            </label>
          </Wrapper>}
          {jobResponseForm.university && <Wrapper>
            <label>Учебное заведение (краткое название)</label>
            <input
              type="text"
              placeholder="ЯКУиПТ"
              value={university}
              onChange={(event) => setUniversity(event.target.value)}
              className={`email-input`}
            />
          </Wrapper>}
          {jobResponseForm.course && <Wrapper>
            <label>Курс</label>
            <input
              type="number"
              value={course}
              onChange={(event) => setCourse(event.target.value)}
              className={`email-input`}
            />
          </Wrapper>}
          {jobResponseForm.cover_letter && <Wrapper>
            <label>Сопроводительное письмо</label>
            <textarea
              placeholder="Меня заинтересовала ваша вакансия, потому что...."
              value={coverLetter}
              onChange={(event) => setCoverLetter(event.target.value)}
              className={`email-input`}
            />
          </Wrapper>}
          <button
            onClick={handleSubmitClick}
            className={`purchase-button`}
            ref={submitButtonRef}
          >
            Отправить
          </button>
        </div>
      </div>
    </div>
  )
}

export default JobReplyPage
