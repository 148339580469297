/*
 * Copyright 2024 LLC Campus.
 */

import { FaRegCopy } from 'react-icons/fa'

/**
 * Компонент с промокодом и его копированием
 * @param code {String} промокод
 * @param handleCopy {Function} Функция обработки копирования
 * @param description {String} Описание (подпись снизу)
 * */
const CopyCode = ({ code, handleCopy, description }) => {
  return (<div>
    <p>Твой код:</p>
    <p>
      <strong>{code}</strong>
      <span className="code-container">
        <FaRegCopy onClick={handleCopy} className="copy-icon" title="Копировать код"/>
      </span>
    </p>
    <p>{description}</p>
  </div>)
}

export default CopyCode