/*
 * Copyright 2024 LLC Campus.
 */

import React, { useState } from 'react'
import { toast } from 'react-toastify'

const EmailInputContainer = ({
  email,
  handleEmailChange,
  isEmailValid,
  handlePurchaseClick,
  selectedItemId,
  infoBlock,
  supportTextBlock
}) => {
  const [lockButton, setLockButton] = useState(false)

  const handleButtonClick = async () => {
    if(lockButton) return
    setLockButton(true)
    try {
      if (!selectedItemId) {
        toast.error('Выберите тариф')
        setLockButton(false)
        return
      }
      if (!email) {
        toast.error('Введите email')
        setLockButton(false)
        return
      }
      if (!isEmailValid) {
        toast.error('Введите корректный email')
        setLockButton(false)
        return
      }
      await handlePurchaseClick()
    } catch (e) {
      // try-catch нужен чтобы гарантировать сброс localStorage'а, но и об ошибке сообщим
      console.error(e)
      toast.error('Неизвестная ошибка')
    }
    setLockButton(false)
  }

  return (
    <div className="email-input-container">
      <input
        type="email"
        placeholder="Введите email"
        value={email}
        onChange={handleEmailChange}
        className={`email-input ${!isEmailValid ? 'invalid' : ''}`}
      />
      {!isEmailValid && <div className="error-message">Введите корректный email</div>}

      <div className="promocode-info"> {infoBlock} </div>

      <button
        onClick={handleButtonClick}
        className={`purchase-button`}
      >
        Перейти к оплате
      </button>
      <div className="support-text"> {supportTextBlock} </div>
    </div>
  )
}

export default EmailInputContainer
