/*
 * Copyright 2024 LLC Campus.
 */

import analytics, { AnalyticsEvents, AnalyticsPresets } from './analytics'

export const openYooKassaWidget = (confirmationToken, promocode, transactionId) => {
  const container = document.getElementById('yookassa-container')
  if (container) {
    container.style.display = 'flex'
  }

  const endpoint = window.location.origin
  const widget = new window.YooMoneyCheckoutWidget({
    confirmation_token: confirmationToken,
    return_url: `${endpoint}/success?transactionId=${transactionId}`,
    customization: {
      color: {
        main: '#000000',
      },
    },
    onSuccess: (data) => {
      console.log('Payment successful:', data)
      analytics.sendEventOnce(AnalyticsEvents.YOOKASSA_SUCCESS, analytics.getLocalStorageMeta())
      closeWidget()
    },
    onError: (error) => {
      console.error('Payment failed:', error)
      analytics.sendEventOnce(AnalyticsEvents.YOOKASSA_FAIL, analytics.getLocalStorageMeta())
      closeWidget()
    },
    onClose: () => {
      console.log('Payment widget closed')
      analytics.sendEventOnce(AnalyticsEvents.YOOKASSA_CLOSED, analytics.getLocalStorageMeta())
      closeWidget()
    },
    error_callback: function (error) {
      window.alert(JSON.stringify(error))
      closeWidget()
    }
  })

  widget.render('yookassa-container')
}

const closeWidget = () => {
  const container = document.getElementById('yookassa-container');
  if (container) {
    container.style.display = 'none'; // Скрываем контейнер
  }
  // Восстановить видимость основного контента
  const catalogContainer = document.querySelector('.promocode-catalog');
  if (catalogContainer) {
    catalogContainer.classList.remove('hidden');
  }
}
